
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.publish-module-card {
    .v-card {
        border-top: 6px solid $faded-blue;
    }
}
